/*
 * over-complicated set of models for generating mock data in
 * specific ranges and with specific precisions
 */

interface UnitRangeModel {
  min: number;
  max: number;
  precision: number;
}

export interface UnitRangesModel {
  [key: string]: UnitRangeModel;
}

export const UNIT_RANGES: UnitRangesModel = {
  default: {
    min: 0,
    max: 1000,
    precision: 0,
  },
  kbps: {
    min: 172.0,
    max: 773.0,
    precision: 4,
  },
  ms: {
    min: 0.3,
    max: 3.2,
    precision: 2,
  },
  time: {
    min: 1585162288665,
    max: 1585162318665,
    precision: 0,
  },
};

export const randomWithPrecision = ({ max, min, precision }: UnitRangeModel): number => {
  const random: number = Math.random() * (max - min) + min;
  const fixed: string = random.toFixed(precision);
  return parseFloat(fixed);
};

export const isAdminPage = (path?: string): boolean => {
  if (path) {
    const pathStrs = path.split('/');
    if (pathStrs.length > 1) {
      return pathStrs[1] === 'admin';
    }
  }
  return false;
};

export const ieVersion = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return -1;
};
