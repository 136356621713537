import { Box } from 'js/component/base/box';
import { colors } from 'js/theme/colors';
import { typography } from 'js/theme/typography';
import React from 'react';
import styled from 'styled-components';

export const BrowserWarning = () => {
  return (
    <Container>
      <MessageTitleLabel>This browser is not supported.</MessageTitleLabel>
      <MessageLabel>
        Please Visit
        <a href="http://doc.haivision.com/HMG3.4" target="_blank">
          Haivision Info Center
        </a>
        for a full list of supported browsers.
      </MessageLabel>
    </Container>
  );
};

const Container = styled(Box)`
  display: block;
  margin: 24px;

  > * {
    color: ${colors.white};
  }
`;

const MessageTitleLabel = styled.div`
  margin-bottom: 24px;
  display: block;
  ${typography.med['4']};
`;
const MessageLabel = styled.div`
  ${typography.book['2']};

  a {
    ${typography.book['2']};
    padding: 0 5px;
  }
`;
