import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

// TODO: Maybe move this out. We should probably expand StyledComponentProps
export interface BaseProps extends HTMLAttributes<HTMLDivElement> {}

export const Box: React.FunctionComponent<BaseProps> = styled.div`
  display: flex;
  flex: none;
  position: relative;
  min-width: 0;
  min-height: 0;
`;
