const palette = {
  gray: {
    1: 'rgb(37, 38, 44)',
    2: 'rgb(51, 51, 60)',
    3: 'rgb(60, 61, 72)',
    4: 'rgb(82, 83, 98)',
    5: 'rgb(109, 111, 129)',
    6: 'rgb(134, 134, 146)',
    7: 'rgb(149, 149, 161)',
    8: 'rgb(185, 186, 203)',
    9: 'rgb(232, 233, 241)',
  },
  white: 'rgb(255, 255, 255)',
  black: 'rgb(0, 0, 0)',
  blue: {
    1: 'rgb(30, 111, 241)',
    2: 'rgb(152, 187, 243)',
    3: 'rgb(0, 114, 206)',
  },
  red: 'rgb(234, 70, 69)',
  green: 'rgb(105, 227, 136)',
  amber: 'rgb(245, 190, 50)',
  aqua: 'rgb(48, 203, 209)',
  purple: 'rgb(149, 112, 227)',
};

export const diskUsagePalette = [
  ['#91d1fa', '#58a4f3'],
  ['#8df8d5', '#55edab'],
  ['#b487f6', '#7340e1'],
  ['#eeb8da', '#d781b3'],
  ['#d781b3', '#ea4645'],
];

export const progressbarColors = {
  left: '#b487f6',
  right: '#d9bfff',
};

export const chartColors = [palette.purple, palette.amber, palette.white];
export const metricsChartColors = [palette.green, palette.purple, palette.amber];

export const colors = {
  ...palette,
};
